<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">
      Device
    </div>
    <BCol class="p-0 d-flex">
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari device, nama partner, no partner"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <div class="ml-[10px] container-date">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'center'"
          :append-to-body="true"
          @update="getListData()"
        >
          <!-- eslint-disable -->
          <template
            v-slot:input="dateRange"
            style="height: 100px"
          >
            <div class="d-flex justify-content-between align-items-center w-100 h-100">
              <div class="mr-1">
                <span
                  v-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(today)"
                  style="color: #828282 !important; height: 100%"
                >
                  Hari ini
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last2)"
                  style="color: #828282 !important"
                >
                  2 Hari Terakhir
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last7)"
                  style="color: #828282 !important"
                >
                  7 Hari Terakhir
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(firstDateOfMonth) && DDMMYYYY(dateRange.endDate) === DDMMYYYY(today)"
                  style="color: #828282 !important"
                >
                  Bulan ini
                </span>
                <span
                  v-else
                  style="color: #828282 !important"
                >
                  {{`${dateRange.startDate ? DDMMYYYY(dateRange.startDate) : ''} - ${dateRange.endDate ? DDMMYYYY(dateRange.endDate) : ''}`}}
                </span>
              </div>
              <div>
                <span class="k-calendar-2" />
              </div>
            </div>
          </template>
        </date-range-picker>
      </div>
      <div class="ml-[10px]">
        <v-select
          style="min-width: 150px"
          v-model="filterType"
          :options="listType"
          :clearable="false"
          placeholder="Tipe Device"
          @input="getListData()"
          :reduce="data => data.value"
        />
      </div>
      <BButton
        tag="router-link"
        to="/device/create"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(partner_name)="data">
            <div class="font-semibold text-7">
              {{ data.item.partner_name }}
            </div>
            <div class="text-6">
              {{ data.item.no_partner }}
            </div>
          </template>
          <template #cell(action)="data">
            <div
              v-if="data.item.device_holder_name === 'IT Support'"
              class="flex justify-end items-center"
            >
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                >
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
              >
                <img
                  v-b-modal="'modalDelete'"
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="Komerce"
                  @click="selectItem(data.item.id)"
                >
              </BButton>
            </div>
            <div
              v-else
              class="flex justify-end items-center"
            >
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-primary"
              >
                <div
                  v-b-modal="'modalDetail'"
                  class="text--blue"
                  @click="selectItem(data.item.id)"
                >Lihat detail</div>
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete
      :id-item="idItem"
      @deleted="getListData()"
    />
    <ModalDetail :id-item="idItem" />
  </BCard>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { DATE_NUMBER_SLASH, DDMMYYYY, YEAR_MONTH_DAY } from '@/libs/filterDate'
import DateRangePicker from 'vue2-daterange-picker'
import vSelect from 'vue-select'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import '@core/scss/vue/libs/vue-select.scss'
import {
  today,
  last2,
  last7,
  firstDateOfMonth,
} from '@/store/helpers'
import {
  dateRange,
  locale,
  ranges,
  tableBiodata,
  listType,
} from './config'
import ModalDelete from './ModalDelete.vue'
import ModalDetail from './ModalDetail.vue'

export default {
  components: {
    ModalDelete,
    ModalDetail,
    DateRangePicker,
    vSelect,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableBiodata,
      idItem: 0,
      filterSelected: [],
      listSkills: [],
      dateRange,
      locale,
      ranges,
      today,
      last2,
      last7,
      firstDateOfMonth,
      DATE_NUMBER_SLASH,
      DDMMYYYY,
      listType,
      filterType: '',
    }
  },
  computed: {
    skill() {
      return this.filterSelected.join(',')
    },
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&device_type=${this.filterType}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
      const url = `v1/devices?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&device_type=${this.filterType}&start_date=${this.dateRange.startDate ? YEAR_MONTH_DAY(this.dateRange.startDate) : ''}&end_date=${this.dateRange.endDate ? YEAR_MONTH_DAY(this.dateRange.endDate) : ''}`
        const url = `v1/devices?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.container-date > div, .container-date > div.vue-daterange-picker > div.form-control.reportrange-text, .form-control, .reportrange-text {
  height: 100% !important;
}
</style>
